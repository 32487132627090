import {
  syncronizeParamsWithUrl,
} from '@/utils/syncUrl'
import router from '@/router'
import storeUtils from '@/utils/store'

export default () => {
  const {
    setValue,
  } = storeUtils('view')

  const initialize = () => {
    const routerQuery = router.history.current.query

    setValue('currentPage', parseInt(routerQuery.page, 10) || 1)
    setValue('totalRows', parseInt(routerQuery.total, 10) || 1)
    setValue('perPage', parseInt(routerQuery.size, 10) || 20)

    const params = syncronizeParamsWithUrl()
    setValue('filterParams', params)
  }

  return {
    initialize,
  }
}
