<template>
  <div>
    <TagbyFilterV2
      :initialParams="$store.state.view.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'message-app-push-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="$store.state.view.currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="$store.state.view.isBusy"
      :current-page="$store.state.view.currentPage"
      :total-rows="$store.state.view.totalRows"
      :per-page="$store.state.view.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'message-app-push-detail', params: { idx: data.value} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(admin)="data">
        <div>
          {{ data.item.admName }}
        </div>
        <div>
          <small class="text-muted">{{ data.item.admEmail }}</small>
        </div>
      </template>

      <template #cell(send_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
} from 'bootstrap-vue'
import storeModule from './storeModule'
import storeUtils from '@/utils/store'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BLink,
    BButton,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
  },
  setup() {
    const STORE_MODULE_NAME = 'view'
    const {
      registerModule,
      mapGetters,
    } = storeUtils(STORE_MODULE_NAME)
    registerModule(storeModule)

    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      items,
    } = mapGetters([
      'items',
    ])

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      fetchItems,
      changePage,
      changeSort,
    } = useItems()
    fetchItems()

    return {
      items,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      fetchItems,
      changePage,
      changeSort,
    }
  },
})
</script>
