import {
  computed,
} from '@vue/composition-api'
import storeUtils from '@/utils/store'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

export default () => {
  const { makeToast } = useToast()

  const {
    setValue,
    mapState,
    mapActions,
  } = storeUtils('view')

  const {
    currentPage,
    perPage,
    totalRows,
    filterParams,
    messageGroupList,
    sortBy,
  } = mapState([
    'currentPage',
    'perPage',
    'totalRows',
    'filterParams',
    'messageGroupList',
    'sortBy',
  ])

  const api = mapActions([
    'fetchMessageGroupList',
    'fetchAdmList',
    'fetchResultCountList',
  ])

  const searchParams = computed(() => ({
    page: currentPage.value,
    size: perPage.value,
    total: totalRows.value,
    ...filterParams.value,
  }))

  const categoryOptions = [
    {
      label: 'CREATED_AT',
      paramName: 'created_at',
      format: 'date',
    },
  ]

  const fetchMessageGroupList = () => api.fetchMessageGroupList({
    ...searchParams.value,
    sort_by: sortBy.value,
  }).then(response => {
    const resData = response.data
    setValue('messageGroupList', resData.data)
    setValue('totalRows', resData.page_info.total_count)
    setValue('filterParams', {
      ...filterParams.value,
      total: resData.page_info.total_count,
    })
  })

  const fetchAdmList = () => api.fetchAdmList({
    adm_idx_list: values(messageGroupList.value, 'admin_idx'),
  }).then(response => {
    setValue('admList', response.data.data)
  })

  const fetchResultCountList = () => api.fetchResultCountList({
    message_group_idx_list: values(messageGroupList.value, 'idx'),
  }).then(response => {
    setValue('resultCountList', response.data.data)
  })

  const fetchItems = () => {
    setValue('isBusy', true)
    fetchMessageGroupList()
      .then(() => fetchAdmList())
      .then(() => fetchResultCountList())
      .then(() => {
        replaceRouterQuery(searchParams.value)
      })
      .catch(err => {
        console.log(err)
        makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
      })
      .finally(() => {
        setValue('isBusy', false)
      })
  }

  const search = params => {
    setValue('currentPage', 1)
    setValue('filterParams', {
      ...params,
    })
    replaceRouterQuery(searchParams.value)
    fetchMessageGroupList()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx', sortable: true },
    { key: 'admin', label: 'admin' },
    { key: 'message', label: 'message' },
    { key: 'target_count', label: 'target_count' },
    { key: 'success', label: 'success' },
    { key: 'fail', label: 'fail' },
    { key: 'state', label: 'state' },
    { key: 'send_at', label: 'send_at' },
    { key: 'created_at', label: 'created_at' },
  ]

  const changePage = params => {
    setValue('currentPage', params.page)
    fetchItems()
  }

  const changeSort = ctx => {
    setValue('sortBy', ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`)
    fetchItems()
  }

  return {
    categoryOptions,
    tableColumns,
    fetchItems,
    search,
    changePage,
    changeSort,
  }
}
