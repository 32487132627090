import axiosFrame from '@/utils/axiosFrame'
import {
  indexing,
  grouping,
} from '@/utils/value'

export default {
  namespaced: true,
  state: {
    currentPage: null,
    perPage: null,
    totalRows: null,
    messageGroupList: [],
    admList: [],
    resultCountList: [],
    isBusy: false,
    filterParams: {},
    sortBy: '-idx',
  },
  getters: {
    items: state => {
      const admByIdx = indexing(state.admList, 'idx')
      const groupedResultCountByMessageGroupIdx = grouping(state.resultCountList, 'message_group_idx')
      return state.messageGroupList.map(m => {
        const adm = admByIdx[m.admin_idx]
        const resultCounts = groupedResultCountByMessageGroupIdx[m.idx]
        const successCountObj = resultCounts?.find(r => r.result === 'SUCCESS')
        const failCountObj = resultCounts?.find(r => r.result === 'FAIL')
        return {
          ...m,
          admIdx: adm?.idx,
          admName: adm?.name,
          admEmail: adm?.email,
          success: successCountObj?.result_count ?? 0,
          fail: failCountObj?.result_count ?? 0,
        }
      })
    },
  },
  mutations: {
    setValue(state, payload) {
      state[payload.name] = payload.value
    },
  },
  actions: {
    fetchMessageGroupList: axiosFrame('post', '/manage/app-push/get-list/', null, true),
    fetchAdmList: axiosFrame('post', '/manage/app-push/get-adm-list/', null, true),
    fetchResultCountList: axiosFrame('post', '/manage/app-push/get-result-count-list/', null, true),
  },
}
